import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  RequestParameterType,
  ResponseDataObject,
  ApiResponse,
  ResponseDataItems,
} from 'app/Views/Process/types'
interface DataObject {
  fetchData: any
  filterEndpoint: string
  requestParameter: RequestParameterType
  filterName: string
}

const fetchBucketFiltersThunk = createAsyncThunk(
  'db/fetchBucketFilters',
  async (dataObject: DataObject) => {
    try {
      const { fetchData, filterEndpoint, requestParameter, filterName } = dataObject || {}

      const response: ApiResponse = await fetchData(filterEndpoint, requestParameter)

      const responseData: ResponseDataObject = response?.data?.data
      const responseDataItemsList: ResponseDataItems[] = responseData?.items
      const responseObjectWithItemsListAndCount = {
        itemsList: responseDataItemsList,
        filterName,
      }

      return responseObjectWithItemsListAndCount
    } catch (error) {
      console.error('There was an error trying to fetch the data from the api', error)
    }
  },
)

export default fetchBucketFiltersThunk
