import {
  AcceptInterviewOfferCodeType,
  AgentEditingUsersProfileType,
  EditInterviewFormQuestionCodeType,
  EditProfileFormQuestionCodeType,
  SettingsFormQuestionCodeType,
  SignInternshipAgreementCodeType,
} from 'app/Display/Header/add-items-menu/types/form-types'
import {
  FORM_ACTION_TYPES,
  POPUP_ACTION_TYPES,
  UserRoleInterface,
  UserTableslistInterface,
  VIEW_ACTION_TYPES,
} from 'app/helpers/types'

export type SuperAgent = 'SUPER AGENT'
export type Admin = 'ADMIN'
export type Agent = 'AGENT'
export type Intern = 'INTERN'
export type HostCompanyRepresentative = 'HOST COMPANY REPRESENTATIVE'
export type HostCompanySupervisor = 'HOST COMPANY SUPERVISOR'
export type EducationProviderRepresentative = 'EDUCATION PROVIDER REPRESENTATIVE'
export type EducationProviderSupervisor = 'EDUCATION PROVIDER SUPERVISOR'
export type ProgramProviderRepresentative = 'PROGRAM PROVIDER REPRESENTATIVE'
export type ProgramProviderSupervisor = 'PROGRAM PROVIDER SUPERVISOR'

export const userRolesList: Readonly<UserRoleInterface> = {
  Agent: 'AGENT',
  Intern: 'INTERN',
  Admin: 'ADMIN',
  HCRep: 'HOST COMPANY REPRESENTATIVE',
  HCSupervisor: 'HOST COMPANY SUPERVISOR',
  PPRep: 'PROGRAM PROVIDER REPRESENTATIVE',
  PPSupervisor: 'PROGRAM PROVIDER SUPERVISOR',
  SuperAgent: 'SUPER AGENT',
  EPRep: 'EDUCATION PROVIDER REPRESENTATIVE',
  EPSupervisor: 'EDUCATION PROVIDER SUPERVISOR',
}

export const userTablesList: Readonly<UserTableslistInterface> = {
  HCStaff: 'HC_STAFF',
  EPStaff: 'EP_STAFF',
  PPStaff: 'PP_STAFF',
}

export const userDetailViewList: UserTableslistInterface & UserRoleInterface = {
  ...userRolesList,
  ...userTablesList,
}

export const organisationsDetailViewList = {
  hostCompanies: 'COMPANIES',
  educationProviders: 'EDUCATION_PROVIDER',
}

export const viewActionTypes: VIEW_ACTION_TYPES[] = [
  'ACT_VIEW',
  'ACT_VIEW_LOGBOOKS',
  'ACT_VIEW_INTERN_PROFILE',
  'ACT_APPLICATION_DETAILS',
]

export const popupActionTypes: POPUP_ACTION_TYPES[] = [
  'ACT_WITHDRAW_INTERN',
  'ACT_REJECT_APPLICATION',
  'ACT_TERMINATE_INTERNSHIP',
  'ACT_APPLY',
]

export const formBasedActionTypes: FORM_ACTION_TYPES[] = [
  'ACT_OFFER_INTERVIEW',
  'ACT_EDIT',
  'ACT_REVIEW_INTERVIEW',
  'ACT_SIGN_INTERNSHIP_AGREEMENT',
]

export const settingsFormCode: SettingsFormQuestionCodeType = 'SETTINGS_FORM'
export const editProfileFormCode: EditProfileFormQuestionCodeType = 'EDIT_PROFILE_FORM'

export const agentEditingUsersProfile: AgentEditingUsersProfileType =
  'AGENT_EDITING_USERS_PROFILE_FORM'
export const accpetInterviewOfferFormCode: AcceptInterviewOfferCodeType =
  'ACT_REVIEW_INTERNSHIP_OFFER'
export const signInternshipAgreementFormCode: SignInternshipAgreementCodeType =
  'ACT_SIGN_INTERNSHIP_AGREEMENT'
export const editInterviewDetailsFormCode: EditInterviewFormQuestionCodeType =
  'ACT_EDIT_INTERVIEW_DETAILS'
