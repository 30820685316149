import {
  ApiResponse,
  RequestParameterType,
  ResponseDataItems,
  ResponseDataObject,
} from 'app/Views/Process/types'

import { createAsyncThunk } from '@reduxjs/toolkit'

interface DataObject {
  fetchData: any
  bucketEndpoint: string
  requestParameter: RequestParameterType
  relevantBucketCodeForReduxStore: string
  isSearchData: boolean
}

const fetchBucketDataThunk = createAsyncThunk(
  'db/fetchBucketData',
  async (dataObject: DataObject) => {
    const {
      fetchData,
      bucketEndpoint,
      requestParameter,
      relevantBucketCodeForReduxStore,
      isSearchData,
    } = dataObject

    const response: ApiResponse = await fetchData(bucketEndpoint, requestParameter)
    const responseData: ResponseDataObject = response?.data?.data
    const totalItemCount = responseData?.total
    const responseDataItemsList: ResponseDataItems[] = responseData?.items
    const responseObjectWithItemsListAndCount = {
      itemsList: responseDataItemsList,
      totalItemsCount: totalItemCount,
      stage: relevantBucketCodeForReduxStore,
      isSearchData,
    }

    return responseObjectWithItemsListAndCount
  },
)

export default fetchBucketDataThunk
